import FilterProvider from '../../../provider/Filter.provider';

import { useSchedulingPermissions } from '../../../hook/useSchedulingPermissions';

import { SchedulingDashboard } from '../../../component/scheduling/dashboard/SchedulingDashboard';

export const SchedulingDashboardContainer = () => {
  const { mappedSchedulingUsers } = useSchedulingPermissions();

  return (
    <FilterProvider>
      <SchedulingDashboard users={mappedSchedulingUsers} />
    </FilterProvider>
  );
};
