import { isEmpty } from '@neslotech/utils';

import profileIcon from '../icon/profile-icon.svg';

export const mapSchedulingUsers = (permissions, users) => {
  if (isEmpty(permissions) || isEmpty(users)) {
    return [];
  }

  return users
    .filter((user) => permissions.some((permission) => permission.userId === user.id))
    .map((user) => ({
      id: user.id,
      image: user.image ?? user.defaultImage ?? profileIcon,
      department: user.department,
      jobTitle: user.jobTitle,
      role: user.role,
      firstName: user.firstName,
      lastName: user.lastName
    }));
};
