const STORE_NAME = '[SCHEDULING_PERMISSIONS_STORE]';
export const SET_ALL_SCHEDULING_PERMISSIONS = `${STORE_NAME} Set All Scheduling Permissions`;
export const SET_USER_SCHEDULING_PERMISSION = `${STORE_NAME} Set Scheduling Permission`;

export const SAVE_SCHEDULING_PERMISSIONS = `${STORE_NAME} Save Scheduling Permissions`;
export const saveSchedulingPermissions = (payload, onSuccess, onError) => ({
  type: SAVE_SCHEDULING_PERMISSIONS,
  payload,
  onSuccess,
  onError
});

export const LOAD_ALL_SCHEDULING_PERMISSIONS = `${STORE_NAME} Load All Permissions`;
export const loadAllSchedulingPermissions = (navigate, onComplete) => ({
  type: LOAD_ALL_SCHEDULING_PERMISSIONS,
  navigate,
  onComplete
});

export const LOAD_USER_SCHEDULING_PERMISSION = `${STORE_NAME} Load User Permission`;
export const loadUserSchedulingPermission = (userId, navigate) => ({
  type: LOAD_USER_SCHEDULING_PERMISSION,
  userId,
  navigate
});

export const LOAD_MY_SCHEDULING_PERMISSION = `${STORE_NAME} Load My Permission`;
export const loadMySchedulingPermission = (userId) => ({
  type: LOAD_MY_SCHEDULING_PERMISSION,
  userId
});
