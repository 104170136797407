import { arrayOf, bool, func, number, object, oneOfType, shape, string } from 'prop-types';
import React, { useCallback, useMemo, useState } from 'react';

import { getClassNames, isEmpty, noOp } from '@neslotech/utils';

import { Dropdown } from '../../../common/component/dropdown/Dropdown';
import { SearchInput } from '../../../common/component/input/search/SearchInput';

import { ReactComponent as ChevronIcon } from '../../../icon/chevron-icon.svg';
import { ReactComponent as PlusIcon } from '../../../icon/plus-icon.svg';

import './search-options-with-add.scss';

export const SearchOptionsWithAdd = ({
  name,
  options,
  label,
  secondaryLabel,
  value,
  error,
  required,
  disabled,
  onChange
}) => {
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');

  const memoizedValue = useMemo(() => {
    const option = options.find((option) => option.value === value);
    return option ? option.label : searchValue;
  }, [options, value, searchValue]);

  const handleChange = useCallback(
    (val) => {
      const option = options.find((option) => option.value === val);
      onChange({ [name]: option ? val : searchValue });
      setSearchValue(option ? option.label : val);
      setOpen(false);
    },
    [name, options, onChange, searchValue]
  );

  const memoizedOptions = useMemo(() => {
    const filteredOptions = options
      .filter((option) =>
        isEmpty(searchValue) ? true : option.label.toLowerCase().includes(searchValue.toLowerCase())
      )
      .map(({ label, value: optionValue }) => ({
        text: (
          <span className="search-options-with-add__option">
            <div
              className={getClassNames('search-options-with-add__option-ellipsis', {
                checked: optionValue === value
              })}
            />
            <span>{label}</span>
          </span>
        ),
        onClick: () => handleChange(optionValue)
      }));

    if (isEmpty(filteredOptions) && !isEmpty(searchValue)) {
      filteredOptions.push({
        text: (
          <span className="search-options-with-add__no-results">
            <PlusIcon /> {searchValue}
          </span>
        ),
        onClick: () => handleChange(searchValue)
      });
    }

    return filteredOptions;
  }, [options, value, searchValue, handleChange]);

  return (
    <div className={getClassNames('search-options-with-add', { open })}>
      <Dropdown
        expandable={disabled}
        menuItems={memoizedOptions}
        placement="bottom-start"
        sameWidth
      >
        <SearchInput
          id={name}
          name={name}
          label={label}
          secondaryLabel={secondaryLabel}
          value={memoizedValue}
          error={error}
          onFocus={() => setOpen(true)}
          action={<ChevronIcon />}
          onActionClick={() => setOpen(!open)}
          onChange={onChange}
          onSearchChange={(val) => setSearchValue(val)}
          searchValue={searchValue}
          required={required}
        />
      </Dropdown>
    </div>
  );
};

SearchOptionsWithAdd.defaultProps = {
  label: '',
  secondaryLabel: '',
  value: '',
  error: null,
  required: false,
  disabled: false,
  onChange: noOp
};

SearchOptionsWithAdd.propTypes = {
  name: string.isRequired,
  options: arrayOf(
    shape({
      label: string,
      value: oneOfType([number, string, object])
    })
  ).isRequired,
  label: string,
  secondaryLabel: string,
  value: oneOfType([number, string]),
  error: string,
  required: bool,
  disabled: bool,
  onChange: func
};
