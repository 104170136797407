const STORE_NAME = '[TASK_ALLOCATION_STORE]';

export const CREATE_TASK_ALLOCATION = `${STORE_NAME} Create task allocation`;
export const createTaskAllocation = (taskAllocation, onSuccess, onComplete) => ({
  type: CREATE_TASK_ALLOCATION,
  taskAllocation,
  onSuccess,
  onComplete
});

export const CREATE_USER_TASK_ALLOCATION = `${STORE_NAME} Create user task allocation`;
export const createUserTaskAllocation = (userId, taskAllocation, onSuccess, onComplete) => ({
  type: CREATE_USER_TASK_ALLOCATION,
  userId,
  taskAllocation,
  onSuccess,
  onComplete
});
