import { arrayOf, bool, node, oneOfType } from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getPlainClassNames } from '@neslotech/utils';

import {
  KUDOS_MOST_NOMINATED_CATEGORIES,
  KUDOS_NOMINATION,
  KUDOS_REMAINING_POINTS,
  NOTIFICATIONS_RECEIVED,
  POINTS_RECEIVED
} from '../../tool/pusher.helper';

import {
  addRealtimePersonalNomination,
  addRealtimePoints,
  setRealTimeRemainingPoints,
  setRealtimeMostFrequentCategories
} from '../../state/action/kudos/kudos.actions';
import { addRealtimeNotification } from '../../state/action/notification.actions';
import { loadContextUser } from '../../state/action/profile.actions';

import { MobileBackAction } from '../../common/component/back-action/MobileBackAction';

import { useAuth } from '../../hook/useAuth';
import { useChannel } from '../../hook/useChannel';
import { useDevice } from '../../hook/useDevice';

import { Breadcrumb } from '../../common/component/breadcrumb/Breadcrumb';
import { NavBar } from '../../common/component/nav/NavBar';
import { NavMenu } from '../../common/component/nav/NavMenu';

import './app-layout.scss';

const AppLayout = ({
  showBreadcrumb,
  fullWidthBreadcrumb,
  showMobileBackButton,
  withSideMenu,
  children
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { id } = useAuth();
  const { mobile } = useDevice();
  const { bindToEvent } = useChannel();

  const [openMenu, setOpenMenu] = useState(false);

  const contextUser = useSelector(({ profile_store }) => profile_store.contextUser);

  useEffect(() => {
    if (id) {
      dispatch(loadContextUser(id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    if (!contextUser) {
      return;
    }

    bindToEvent(NOTIFICATIONS_RECEIVED, (data) => dispatch(addRealtimeNotification(data)));
    bindToEvent(POINTS_RECEIVED, (data) => dispatch(addRealtimePoints(data)));
    bindToEvent(KUDOS_NOMINATION, (data) => dispatch(addRealtimePersonalNomination(data)));
    bindToEvent(KUDOS_MOST_NOMINATED_CATEGORIES, (data) =>
      dispatch(setRealtimeMostFrequentCategories(data))
    );
    bindToEvent(KUDOS_REMAINING_POINTS, (data) =>
      dispatch(setRealTimeRemainingPoints(data.remainingPoints))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contextUser]);

  return (
    <>
      <NavMenu
        loggedInUserRole={contextUser?.role}
        open={openMenu}
        onClose={() => setOpenMenu(false)}
      />

      <NavBar
        profile={contextUser}
        onMenuOpen={() => setOpenMenu(true)}
        contextLabel="Kudos"
        onContextClick={() => navigate('/kudos')}
      />

      <main className={getPlainClassNames({ 'with-side-menu': withSideMenu })}>
        {!mobile && showBreadcrumb && <Breadcrumb fullWidth={fullWidthBreadcrumb} />}
        {mobile && showMobileBackButton && <MobileBackAction onClick={() => navigate(-1)} />}
        {children}
      </main>
    </>
  );
};

AppLayout.defaultProps = {
  showBreadcrumb: true,
  showMobileBackButton: true,
  fullWidthBreadcrumb: false
};

AppLayout.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  showBreadcrumb: bool,
  fullWidthBreadcrumb: bool,
  showMobileBackButton: bool
};

export default AppLayout;
