const STORE_NAME = '[LEAVE_ALLOCATION_STORE]';

export const CREATE_LEAVE_ALLOCATION = `${STORE_NAME} Create leave allocation`;
export const createLeaveAllocation = (leaveAllocation, onSuccess, onComplete) => ({
  type: CREATE_LEAVE_ALLOCATION,
  leaveAllocation,
  onSuccess,
  onComplete
});

export const CREATE_USER_LEAVE_ALLOCATION = `${STORE_NAME} Create user leave allocation`;
export const createUserLeaveAllocation = (userId, leaveAllocation, onSuccess, onComplete) => ({
  type: CREATE_USER_LEAVE_ALLOCATION,
  userId,
  leaveAllocation,
  onSuccess,
  onComplete
});
