import { func } from 'prop-types';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import { loadAllClients } from '../../../../../state/action/scheduling/client.actions';
import { updateProject } from '../../../../../state/action/scheduling/project.actions';

import { Loader } from '../../../../../common/component/loader/Loader';
import ProjectForm from '../../../../../component/scheduling/project/form/ProjectForm';

export const EditProjectDetailsContainer = ({ onCancel, onNext }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();

  const project = useSelector(({ project_store }) => project_store.project);
  const clients = useSelector(({ client_store }) => client_store.clients);

  useEffect(() => {
    if (location.state?.key === 'projectCreated') {
      onNext();
      navigate(location.pathname, { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    dispatch(loadAllClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNext = (payload, onComplete) => {
    dispatch(updateProject(project.id, payload, navigate, onNext, onComplete));
  };

  if (id !== project?.id) {
    return <Loader dark />;
  }

  return (
    <ProjectForm
      editMode
      project={project}
      onCancel={onCancel}
      onSubmit={handleNext}
      clients={clients}
    />
  );
};

EditProjectDetailsContainer.propTypes = {
  onCancel: func.isRequired,
  onNext: func.isRequired
};
