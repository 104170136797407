import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { loadAllClients } from '../../../state/action/scheduling/client.actions';
import {
  archiveProject,
  loadAllProjects,
  reinstateProject
} from '../../../state/action/scheduling/project.actions';

import { useProgressLoader } from '../../../hook/useProgressLoader';

import { ProjectList } from '../../../component/scheduling/project/ProjectList';

const ProjectListContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();

  const stateLoading = useSelector(({ project_store }) => project_store.loading);
  const projects = useSelector(({ project_store }) => project_store.projects);
  const clients = useSelector(({ client_store }) => client_store.clients);

  const [loading, setLoading] = useState(stateLoading ?? true);

  useEffect(() => {
    dispatch(loadAllProjects(navigate, () => setLoading(false)));
    dispatch(loadAllClients());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleArchiveProject = (projectId, onComplete) => {
    setBusyState(true);
    dispatch(
      archiveProject(projectId, navigate, () => {
        onComplete();
        setBusyState(false);
      })
    );
  };

  const handleReinstateProject = (projectId) => {
    setBusyState(true);
    dispatch(reinstateProject(projectId, navigate, () => setBusyState(false)));
  };

  return (
    <ProjectList
      loading={loading}
      projects={projects}
      clients={clients}
      onArchive={handleArchiveProject}
      onReinstate={handleReinstateProject}
    />
  );
};

export default ProjectListContainer;
