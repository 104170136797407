import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty } from '@neslotech/utils';

import {
  loadAllSchedulingPermissions,
  saveSchedulingPermissions
} from '../../../state/action/scheduling/scheduling-permissions.actions';
import { loadUsers } from '../../../state/action/user.action';

import { useProgressLoader } from '../../../hook/useProgressLoader';

import SchedulingUserAccess from '../../../component/scheduling/access/SchedulingUserAccess';

const SchedulingUserAccessContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { setBusyState } = useProgressLoader();

  const stateLoading = useSelector(({ user_store }) => user_store.loading);
  const users = useSelector(({ user_store }) =>
    user_store.users?.filter((user) => user.active === true)
  );
  const permissions = useSelector(
    ({ scheduling_permissions_store }) => scheduling_permissions_store.permissions
  );

  const [loading, setLoading] = useState(stateLoading ?? true);

  const stopLoading = () => setLoading(false);

  useEffect(() => {
    dispatch(loadUsers(stopLoading));
    dispatch(loadAllSchedulingPermissions(navigate, () => setLoading(false)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const noPermissions = isEmpty(permissions);

  const onSubmit = (data) => {
    setBusyState(true);
    dispatch(
      saveSchedulingPermissions(
        data,
        () => {
          setBusyState(false);
          if (noPermissions) {
            navigate('/scheduling/projects');
          }
        },
        () => setBusyState(false)
      )
    );
  };

  return (
    <SchedulingUserAccess
      users={users}
      permissions={permissions}
      loading={loading}
      onSubmit={onSubmit}
      setup={noPermissions}
    />
  );
};

export default SchedulingUserAccessContainer;
