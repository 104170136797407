import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL, SNACK_SUCCESS } from '@neslotech/utils';

import { getAuthHeaders } from '../../../tool/auth.util';
import {
  PROJECTS_ENDPOINT,
  getProjectEndpoint,
  getTeamMembersEndpoint
} from '../../tool/api/scheduling/project.endpoint';

import {
  ARCHIVE_PROJECT,
  CREATE_PROJECT,
  LOAD_ALL_PROJECTS,
  LOAD_PROJECT,
  REINSTATE_PROJECT,
  SAVE_TEAM_MEMBERS,
  SET_PROJECT,
  SET_PROJECTS,
  UPDATE_PROJECT,
  loadAllProjects
} from '../../action/scheduling/project.actions';
import { addSystemNotice } from '../../action/system.actions';

export function* performLoadAllProjects({ navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      PROJECTS_ENDPOINT,
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_PROJECTS, projects: data });
  } catch ({ response }) {
    yield put(addSystemNotice('Failed to load projects', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadAllProjects() {
  yield takeLatest(LOAD_ALL_PROJECTS, performLoadAllProjects);
}

export function* performLoadProject({ id, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getProjectEndpoint(id),
      HttpVerb.GET,
      getAuthHeaders()
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_PROJECT, project: data });
  } catch ({ response }) {
    yield put(addSystemNotice('Failed to load project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForLoadProject() {
  yield takeLatest(LOAD_PROJECT, performLoadProject);
}

export function* performCreateProject({ payload, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      PROJECTS_ENDPOINT,
      HttpVerb.POST,
      getAuthHeaders(),
      payload
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_PROJECT, project: data });

    yield call(navigate, `../${data.id}/edit`, { state: { key: 'projectCreated' } });
  } catch ({ response }) {
    yield put(addSystemNotice('There was an error creating the project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateProjectRequest() {
  yield takeLatest(CREATE_PROJECT, performCreateProject);
}

export function* performUpdateProject({ id, payload, navigate, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getProjectEndpoint(id),
      HttpVerb.PUT,
      getAuthHeaders(),
      payload
    );

    const { data } = yield call(axios, endpoint, axiosOptions);

    yield put({ type: SET_PROJECT, project: data });
    yield put(addSystemNotice('The project has been updated successfully', SNACK_SUCCESS));

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(addSystemNotice('There was an error updating the project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForUpdateProjectRequest() {
  yield takeLatest(UPDATE_PROJECT, performUpdateProject);
}

export function* performSaveTeamMembers({ projectId, payload, navigate, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getTeamMembersEndpoint(projectId),
      HttpVerb.POST,
      getAuthHeaders(),
      payload
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(addSystemNotice('There was an error creating the project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForSaveTeamMembersRequest() {
  yield takeLatest(SAVE_TEAM_MEMBERS, performSaveTeamMembers);
}

export function* performArchiveProject({ projectId, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      getProjectEndpoint(projectId),
      HttpVerb.DELETE,
      getAuthHeaders()
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('The project has been archived successfully', SNACK_SUCCESS));
    yield put(loadAllProjects(navigate));
  } catch ({ response }) {
    yield put(addSystemNotice('There was an error archiving the project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForArchiveProject() {
  yield takeLatest(ARCHIVE_PROJECT, performArchiveProject);
}

export function* performReinstateProject({ projectId, navigate, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${getProjectEndpoint(projectId)}/reinstate`,
      HttpVerb.PUT,
      getAuthHeaders(),
      {}
    );

    yield call(axios, endpoint, axiosOptions);

    yield put(addSystemNotice('The project has been reinstated successfully', SNACK_SUCCESS));
    yield put(loadAllProjects(navigate));
  } catch ({ response }) {
    yield put(addSystemNotice('There was an error reinstating the project', SNACK_CRITICAL));
  } finally {
    yield call(onComplete);
  }
}

export function* watchForReinstateProject() {
  yield takeLatest(REINSTATE_PROJECT, performReinstateProject);
}

export default function* projectSaga() {
  yield all([
    watchForLoadAllProjects(),
    watchForCreateProjectRequest(),
    watchForSaveTeamMembersRequest(),
    watchForArchiveProject(),
    watchForReinstateProject(),
    watchForLoadProject(),
    watchForUpdateProjectRequest()
  ]);
}
