import { arrayOf, bool, func, shape, string } from 'prop-types';
import React, { useMemo, useState } from 'react';

import { LEAVE_ALLOCATION_TAB_OPTIONS } from './scheduling-allocation-form.helper';

import { Modal } from '../../../../common/layout/portal/Modal';
import { ModalLayout } from '../../../../common/layout/portal/ModalLayout';
import Tabs from '../../../tabs/Tabs';
import { LeaveAllocationForm } from './leave-allocation/LeaveAllocationForm';
import { TaskAllocationForm } from './task-allocation/TaskAllocationForm';

import './scheduling-allocation-form.scss';

export const SchedulingAllocationForm = ({
  onAddLeaveAllocation,
  onClose,
  categories = [],
  isAdmin = false,
  users = [],
  loggedInUserId,
  openedTab,
  projects = [],
  tasks = [],
  onAddTaskAllocation,
  setProjectId
}) => {
  const [selectedTab, setSelectedTab] = useState(openedTab);

  const allocationForm = useMemo(() => {
    if (selectedTab === 'Time Off') {
      return (
        <LeaveAllocationForm
          onSubmit={onAddLeaveAllocation}
          categories={categories}
          loggedInUserId={loggedInUserId}
          users={users}
          isAdmin={isAdmin}
          onClose={onClose}
        />
      );
    } else if (selectedTab === 'Allocation') {
      return (
        <TaskAllocationForm
          onSubmit={onAddTaskAllocation}
          tasks={tasks}
          projects={projects}
          loggedInUserId={loggedInUserId}
          users={users}
          isAdmin={isAdmin}
          onClose={onClose}
          setProjectId={setProjectId}
        />
      );
    }
    return <></>;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, tasks]);

  return (
    <Modal className="scheduling-allocation-form">
      <ModalLayout onClose={onClose}>
        <div className="scheduling-allocation-form__tabs">
          <Tabs
            selectedTab={selectedTab}
            hideFirst
            tabs={LEAVE_ALLOCATION_TAB_OPTIONS}
            setSelectedTab={setSelectedTab}
          />
        </div>
        {allocationForm}
      </ModalLayout>
    </Modal>
  );
};

SchedulingAllocationForm.propTypes = {
  onClose: func.isRequired,
  isAdmin: bool,
  users: arrayOf(
    shape({
      firstName: string,
      lastName: string,
      id: string
    })
  ),
  categories: arrayOf(
    shape({
      name: string,
      id: string
    })
  ),
  tasks: arrayOf(
    shape({
      title: string,
      id: string
    })
  ),
  projects: arrayOf(
    shape({
      name: string,
      id: string
    })
  ),
  loggedInUserId: string.isRequired,
  onAddLeaveAllocation: func.isRequired,
  onAddTaskAllocation: func.isRequired
};
