import { all, call, put, takeLatest } from '@redux-saga/core/effects';
import axios from 'axios';

import { ApiRequest, HttpVerb, SNACK_CRITICAL } from '@neslotech/utils';

import { getAuthHeaders } from '../../../../tool/auth.util';
import { SCHEDULING_ENDPOINT } from '../../../tool/api/scheduling/scheduling-permissions.endpoint';

import {
  CREATE_TASK_ALLOCATION,
  CREATE_USER_TASK_ALLOCATION
} from '../../../action/scheduling/task-allocation/task-allocation.actions';
import { addSystemNotice } from '../../../action/system.actions';

export function* performCreateTaskAllocation({ taskAllocation, onSuccess, onComplete }) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/task-allocations`,
      HttpVerb.POST,
      getAuthHeaders(),
      taskAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(
      addSystemNotice('The was an error while creating the time allocation', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateTaskAllocation() {
  yield takeLatest(CREATE_TASK_ALLOCATION, performCreateTaskAllocation);
}

export function* performCreateUserTaskAllocation({
  userId,
  taskAllocation,
  onSuccess,
  onComplete
}) {
  try {
    const { endpoint, axiosOptions } = new ApiRequest(
      `${SCHEDULING_ENDPOINT}/users/${userId}/task-allocations`,
      HttpVerb.POST,
      getAuthHeaders(),
      taskAllocation
    );

    yield call(axios, endpoint, axiosOptions);

    yield call(onSuccess);
  } catch ({ response }) {
    yield put(
      addSystemNotice('There was an error while creating the time allocation', SNACK_CRITICAL)
    );
  } finally {
    yield call(onComplete);
  }
}

export function* watchForCreateUserTaskAllocation() {
  yield takeLatest(CREATE_USER_TASK_ALLOCATION, performCreateUserTaskAllocation);
}

export default function* taskAllocationSaga() {
  yield all([watchForCreateTaskAllocation(), watchForCreateUserTaskAllocation()]);
}
