import { func, string } from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { isEmpty, noOp } from '@neslotech/utils';

import { ROLES } from '../../../tool/constant';

import {
  createLeaveAllocation,
  createUserLeaveAllocation
} from '../../../state/action/scheduling/leave-allocation/leave-allocation.actions';
import { loadAllProjects } from '../../../state/action/scheduling/project.actions';
import { loadAllLeaveCategories } from '../../../state/action/scheduling/settings/leave-category.actions';
import {
  createTaskAllocation,
  createUserTaskAllocation
} from '../../../state/action/scheduling/task-allocation/task-allocation.actions';
import { loadTasks } from '../../../state/action/scheduling/task.actions';

import { useProgressLoader } from '../../../hook/useProgressLoader';
import { useSchedulingPermissions } from '../../../hook/useSchedulingPermissions';

import { SchedulingAllocationForm } from '../../../component/scheduling/allocation/form/SchedulingAllocationForm';

export const SchedulingAllocationContainer = ({ onClose, selectedTab }) => {
  const [projectId, setProjectId] = useState();

  const dispatch = useDispatch();
  const { mappedSchedulingUsers } = useSchedulingPermissions();
  const { setBusyState } = useProgressLoader();
  const navigate = useNavigate();

  const user = useSelector(({ profile_store }) => profile_store.contextUser);
  const leaveCategories = useSelector(
    ({ leave_category_store }) => leave_category_store.categories
  );
  const projects = useSelector(({ project_store }) => project_store.projects);
  const tasks = useSelector(({ task_store }) => task_store.tasks);

  const isAdmin = user.role === ROLES.ADMIN;

  const handleAddLeaveAllocation = (leaveAllocation, userId) => {
    setBusyState(true);

    if (isAdmin && userId) {
      dispatch(
        createUserLeaveAllocation(userId, leaveAllocation, onClose, () => setBusyState(false))
      );
    } else {
      dispatch(createLeaveAllocation(leaveAllocation, onClose, () => setBusyState(false)));
    }
  };

  const handleAddTaskAllocation = (taskAllocation, userId) => {
    setBusyState(true);

    if (isAdmin && userId) {
      dispatch(
        createUserTaskAllocation(userId, taskAllocation, onClose, () => setBusyState(false))
      );
    } else {
      dispatch(createTaskAllocation(taskAllocation, onClose, () => setBusyState(false)));
    }
  };

  useEffect(() => {
    if (projectId) {
      dispatch(loadTasks(projectId, noOp));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectId]);

  useEffect(() => {
    if (isEmpty(projects)) {
      dispatch(loadAllProjects(navigate));
    }

    if (isEmpty(leaveCategories)) {
      dispatch(loadAllLeaveCategories());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SchedulingAllocationForm
      users={mappedSchedulingUsers}
      categories={leaveCategories}
      isAdmin={isAdmin}
      onAddLeaveAllocation={handleAddLeaveAllocation}
      onAddTaskAllocation={handleAddTaskAllocation}
      onClose={onClose}
      loggedInUserId={user.id}
      openedTab={selectedTab}
      tasks={tasks}
      projects={projects}
      setProjectId={setProjectId}
    />
  );
};

SchedulingAllocationContainer.propTypes = {
  onClose: func.isRequired,
  selectedTab: string.isRequired
};
